import React, {useEffect, useState} from "react";
import useCollapse from "react-collapsed";

import {parseBetEvent, toString} from "../Utilities.jsx";
import {bettingInstance} from "../../utils/getWeb3.js";
import "../../App.css";


function Collapsible(betEvent) {
    const e = betEvent;
    const [isExpanded, setExpanded] = useState(false);
    const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

    function handleOnClick() {
        // Do more stuff with the click event!
        // Or, set isExpanded conditionally
        setExpanded(!isExpanded);
    }

    return (<div className="collapsible">
        <div className="header"  {...getToggleProps({onClick: handleOnClick})}>
            <div className="title">
                {toString(e.name)}
            </div>
            <div className="icon">
                <i className={"fas fa-chevron-circle-" + (isExpanded
                    ? "up"
                    : "down")}></i>
            </div>
        </div>
        <div {...getCollapseProps()}>
            <div className="content">
                {Object.entries(parseBetEvent(e)).map(([k, v]) => <div key={e.name + "_" + k}>
                    <span> {k}: {v}</span>
                    <br/>
                </div>)}
            </div>
        </div>
    </div>);
}


function ListEvents() {

    const [betEvents, setBetEvents] = useState([]);

    const getBetEvents = () => {
        return bettingInstance.then(({instance, accounts}) => {
            return instance.getBetEvents(accounts[0], {from: accounts[0], value: 0});
        }).then(results => setBetEvents(results));
    };

    // const handleMetamaskChange = () => {
    //     if (window.ethereum) {
    //         // // use MetaMask's provider
    //         // App.web3 = new Web3(window.ethereum);
    //         window.ethereum.enable(); // get permission to access accounts
    //         // detect Metamask account change
    //         window.ethereum.on('accountsChanged', function (accounts) {
    //             console.log('accountsChanges', accounts);
    //             refresh();
    //         });
    //         // detect Network account change
    //         window.ethereum.on('chainChanged', function (networkId) {
    //             console.log('chainChanged', networkId);
    //             refresh();
    //         });
    //         // detect confirmation
    //         window.ethereum.on('confirmation', function (confirmation) {
    //             console.log('confirmation', confirmation);
    //             refresh();
    //         });
    //     } else {
    //         console.warn(
    //             "No web3 detected. Falling back to http://127.0.0.1:8545. You should remove this fallback when you deploy live",
    //         );
    //         // // fallback - use your fallback strategy (local node / hosted node + in-dapp id mgmt / fail)
    //         // App.web3 = new Web3(
    //         //     new Web3.providers.HttpProvider("http://127.0.0.1:7545"),
    //         // );
    //     }
    // };

    // useEffect(() => {
    //     // const handleMetamaskChange = _handleMetamaskChange()
    //     window.addEventListener("load", handleMetamaskChange);
    //     return () => {
    //         window.addEventListener("load", handleMetamaskChange);
    //     };
    // }, [handleMetamaskChange]);

    useEffect(() => {
        getBetEvents();
    });

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         refresh();
    //     }, 5000);
    //     return () => clearInterval(interval);
    // });

    return (<div>
        <h3>Event List</h3>
        <hr/>
        {/* <h5>Details</h5>
            <br />
            <h5>Event name</h5> */}
        <div>
            {betEvents.map((betEvent) => (<div key={betEvent.name}>
                <Collapsible {...betEvent} />
            </div>))}
        </div>
    </div>);
}


export default ListEvents;
