import React from "react";
import {NavLink} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {Card, Space, Timeline, Typography} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";

import deadpootinImg from "../img/deadpootin.png";
import deadpoohpingImg from "../img/deadpoohping.png";

const {Title, Text} = Typography;
const {Meta} = Card;

const styles = {
    title: {
        fontSize: "20px", fontWeight: "700",
    }, text: {
        fontSize: "16px",
    }, card: {
        boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)", border: "1px solid #e7eaf3", borderRadius: "0.5rem",
    }, timeline: {
        marginBottom: "-45px",
    },
};

function Home({isServerInfo}) {

    return (<Space direction="vertical" align="center" style={{display: "flex", justifyContent: "center"}}>
        <Card
            style={styles.card}
            title={<>
                💣 <Text strong>You Know How It Works...</Text>
            </>}
        >
            <Timeline mode="left" style={styles.timeline}>
                <Timeline.Item dot="💀">
                    <Text style={styles.text}>
                        Choose the <b>Dead Pool</b>
                    </Text>
                </Timeline.Item>
                <Timeline.Item dot="💸">
                    <Text style={styles.text}>
                        Bet on the date
                    </Text>
                </Timeline.Item>
                <Timeline.Item dot="🪦">
                    <Text style={styles.text}>
                        Wait for it to happen... 😵
                    </Text>
                </Timeline.Item>
                <Timeline.Item dot="💰">
                    <Text style={styles.text} type="success" strong>
                        Get your winnigs!
                    </Text>
                </Timeline.Item>
                <Timeline.Item dot="🔏">
                    <Text style={styles.text}>
                        If nobody gets it right, <b>everybody gets the money back</b>
                    </Text>
                </Timeline.Item>
            </Timeline>
        </Card>
        <HashLink smooth to="/#deadPoolList">
            <div className="arrow-down-container">
                <div className="arrow-down"></div>
            </div>
        </HashLink>
        <br/>
        <h1 id="deadPoolList" style={styles.title}>Dead Pools</h1>
        <NavLink to="/deadpootin">
            <Card
                hoverable
                style={{width: 240}}
                cover={<img alt="deadpootin" src={deadpootinImg} style={{padding: 5}}/>}
                actions={[<ArrowRightOutlined key="bet"/>]}
            >
                <Meta
                    title={<Title level={3} type="danger" style={{marginBottom: 0, letterSpacing: "2px"}}>
                        DeadPooTIN
                    </Title>}
                    style={{textAlign: "center"}}
                />
            </Card>
        </NavLink>
        <NavLink to="/deadpoohping">
            <Card
                hoverable
                style={{width: 240}}
                cover={<img alt="deadpoohping" src={deadpoohpingImg} style={{padding: 5}}/>}
                actions={[<ArrowRightOutlined key="bet"/>]}
            >
                <Meta
                    title={<Title level={3} type="danger" style={{marginBottom: 0, letterSpacing: "2px"}}>
                        DeadPoohPING
                    </Title>}
                    style={{textAlign: "center"}}
                />
            </Card>
        </NavLink>
    </Space>);
}

export default Home;
