import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Alert,
    Button,
    Calendar,
    Col,
    DatePicker,
    Descriptions,
    InputNumber,
    PageHeader,
    Row,
    Spin,
    Statistic,
    Tag,
    Typography,
} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import "antd/dist/antd.css";
import "../App.css";

import NativeTransactions from "./NativeTransactions.jsx";
import {bidToDate, bidToString, dateToBid, fromWei, toString, toWei} from "./Utilities.jsx";
import {bettingInstance} from "../utils/getWeb3.js";

const {Title, Text} = Typography;


const styles = {
    card: {
        alignItems: "center", width: "100%",
    }, header: {
        textAlign: "center",
    }, input: {
        width: "100%",
        outline: "none",
        fontSize: "16px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textverflow: "ellipsis",
        appearance: "textfield",
        color: "#041836",
        fontWeight: "700",
        border: "none",
        backgroundColor: "transparent",
    }, select: {
        marginTop: "20px", display: "flex", alignItems: "center",
    }, textWrapper: {maxWidth: "80px", width: "100%"}, row: {
        display: "flex", alignItems: "center", gap: "10px", flexDirection: "row",
    },
};


moment.locale("utc", {
    week: {
        dow: 1, doy: 1,
    },
});


const ETH = "♢";   // Ξ, ⧫, ♦, ⬨, ◊, ♢, ⟠

const showLimits = false;
const showFee = false;

const minBetDate = moment.utc().add(1, "days").startOf("day");
const maxExpireDate = moment.utc().startOf("day");


function DeadPool(props) {

    const creator = props.creator;
    const idBetEvent = props.idBetEvent;

    const [cAccounts, setCAccounts] = useState([]);
    const [betEvent, setBetEvent] = useState(null);
    const [bids, setBids] = useState([]);
    const [bets, setBets] = useState([]);
    const [parsedEvent, setParsedEvent] = useState({});
    const [selectedDates, setSelectedDates] = useState([moment.utc().add(1, "days")]);
    const [bidName, setBidName] = useState();
    const [amount, setAmount] = useState();
    const [tx, setTx] = useState();
    const [propsTx, setPropsTx] = useState({});
    const [isPending, setIsPending] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const navigate = useNavigate();

    const parsedBid = (date, account) => {
        var isClosed = true;
        var isWinner = false;
        var amountReceived = 0;
        var amountReceivedAccount = 0;
        if (bids && betEvent) {
            const bid = bids.find(bid => Number(bid.name) === dateToBid(date));
            if (bid) {
                isClosed = bid.status === "1";
                isWinner = Number(bid.name) === Number(betEvent.winner);
                amountReceived = parseFloat(fromWei(bid.amountReceived));
                amountReceivedAccount = bets.filter(
                    bet => Number(bet.bidName) === Number(bid.name) && bet.person === account)
                                            .reduce((acc, obj) => acc + parseFloat(fromWei(obj.amount)), 0);
            }
        }
        return {
            isClosed, isWinner, amountReceived, amountReceivedAccount,
        };
    };

    const simWinnings = (amount, totAmount, add = true) => {
        const numerator = (parsedEvent.jackpot - totAmount) * parsedEvent.feeFactor;
        const denominator = totAmount + (add
            ? amount
            : 0);
        if (add) {
            console.log(numerator / denominator);
        }
        return Number(amount * numerator / denominator) + Number(amount);
    };

    const propWinnings = (amount, totAmount) => {
        return simWinnings(amount, totAmount, false);
    };

    const dateCellRender = (date) => {
        const isExpired = date < maxExpireDate && date.month() === (selectedDates[0]
            ? selectedDates[0].month()
            : moment.utc().month());
        const propsBid = parsedBid(date, cAccounts[0]);
        const isClosed = propsBid.isClosed;
        const isWinner = propsBid.isWinner;
        const amountReceived = propsBid.amountReceived;
        const amountReceivedAccount = propsBid.amountReceivedAccount;
        return (<div>
            {amountReceived > 0
                ? <Tag
                    color={date >= minBetDate
                        ? parsedEvent.isOpen
                            ? !isClosed
                                ? "#108ee9"
                                : "error"
                            : isWinner
                                ? "#87d068"
                                : "error"
                        : "error"}
                    style={{width: "90%"}}
                >
                    Tot Bet
                    <br/>
                    <b>{`${Number(amountReceived).toFixed(2)}${ETH}`}</b>
                </Tag>
                : ""}
            {amountReceivedAccount > 0
                ? <Tag icon={<ExclamationCircleOutlined/>} color="warning" style={{width: "90%"}}>
                    <br/>
                    {`I bet ${Number(amountReceivedAccount).toFixed(2)}${ETH}`}
                    {isWinner
                        ? <span>
                    <br/>
                    I won <b>{propWinnings(amountReceivedAccount, amountReceived).toFixed(4)}{ETH}</b>
                  </span>
                        : !isExpired && !parsedEvent.winner
                            ? <span>
                      <br/>
                      I'd win <b>{propWinnings(amountReceivedAccount, amountReceived).toFixed(4)}{ETH}</b>
                    </span>
                            : ""}
                </Tag>
                : ""}
            {isExpired
                ? <div className="red-cross">{"\u274C"}</div>
                : ""}
        </div>);
    };

    const disabledDate = (date) => {
        const minDate = parsedEvent
            ? parsedEvent.winner
                ? parsedEvent.winner
                : minBetDate
            : minBetDate;
        const isClosed = bids
            ? bids.filter(bid => Number(bid.name) === dateToBid(date) && bid.status === "1").length > 0
            : false;
        return date < minDate || isClosed;
    };

    const PlaceBet = () => {
        setIsPending(true);
        const _amount = toWei(amount);
        const _bidName = dateToBid(bidName);
        if (parsedEvent.isOpen) {
            bettingInstance.then(({instance, accounts}) => {
                const results = instance.makeBet(creator, idBetEvent, _bidName, {from: accounts[0], value: _amount});
                setIsPending(false);
                return results;
            }).then((results) => {
                console.log("bet placed", results);
            }).catch((error) => {
                setIsPending(false);
                console.error("bet not placed", error);
            });
        } else {
            console.log("event is closed");
        }
    };

    useEffect(() => {
        bettingInstance.then(({instance, accounts}) => setCAccounts(accounts));
    }, []);

    useEffect(() => {
        const getBetEvent = () => {
            console.log("getting bet event");
            return bettingInstance.then(({instance, accounts}) => {
                return instance.betEvents(creator, idBetEvent);
            }).then(results => setBetEvent(results)).catch(error => setBetEvent(null));
        };
        getBetEvent();
    }, [creator, idBetEvent]);

    useEffect(() => {
        console.log("betevent", betEvent);
        setRefresh(true);
        setParsedEvent(prevParsedEvent => {
            return {
                ...prevParsedEvent,
                name: betEvent
                    ? toString(betEvent.name)
                    : "",
                fee: betEvent
                    ? parseInt(betEvent.arbitratorFee) / 100
                    : 0,
                feeFactor: 1 - (betEvent
                    ? parseInt(betEvent.arbitratorFee) / 100
                    : 0),
                minBid: betEvent
                    ? parseFloat(fromWei(betEvent.minBid))
                    : 0,
                maxBid: betEvent
                    ? parseFloat(fromWei(betEvent.maxBid))
                    : 0,
                winner: betEvent
                    ? Number(betEvent.winner)
                        ? bidToDate(betEvent.winner)
                        : ""
                    : "",
                isOpen: betEvent
                    ? Number(betEvent.status) === 0
                    : false,
            };
        });
    }, [betEvent]);

    useEffect(() => {
        const getBids = () => {
            console.log("getting bids");
            return bettingInstance.then(({instance, accounts}) => {
                return instance.getBids(creator, idBetEvent, {from: accounts[0], value: 0});
            }).then(results => setBids(results));
        };
        const getBets = () => {
            console.log("getting bets");
            return bettingInstance.then(({instance, accounts}) => {
                return instance.getBets(creator, idBetEvent, {from: accounts[0], value: 0});
            }).then(results => setBets(results));
        };
        if (refresh) {
            getBids();
            getBets();
        }
        setRefresh(false);
    }, [refresh, creator, idBetEvent]);

    useEffect(() => {
        console.log("bids", bids);
        setParsedEvent(prevParsedEvent => {
            const exFee = false;
            const factor = exFee
                ? prevParsedEvent.feeFactor
                : 1;
            return {
                ...prevParsedEvent,
                jackpot: (bids
                    ? bids.reduce((acc, obj) => acc + parseFloat(fromWei(obj.amountReceived)), 0)
                    : 0) * factor,
            };
        });
    }, [bids]);

    useEffect(() => {
        console.log("bets", bets);
    }, [bets]);

    useEffect(() => {
        amount && bidName
            ? setTx({amount, bidName})
            : setTx();
        amount && bidName
            ? setPropsTx(parsedBid(bidToDate(bidName), amount))
            : setPropsTx({});
    }, [amount, bidName]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (betEvent) {
                setRefresh(true);
            }
        }, 5000);
        return () => clearInterval(interval);
    });

    return (<div className="deadpool-wrap">
        <img
            className="deadpool-bg"
            src={props.backgroundImage}
            alt=""
        />
        <div className="deadpool-content">
            <Spin size="large" spinning={!betEvent}>
                <PageHeader
                    className="site-page-header"
                    title={<Title level={1} type="danger" style={{marginBottom: 0, letterSpacing: "5px"}}>
                        {parsedEvent.name}
                    </Title>}
                    onBack={() => navigate("/")}
                    subTitle={!parsedEvent.isOpen
                        ? "event is closed, no more bets"
                        : ""}
                />
                <Row gutter={16 + 8 * 2}>
                    <Col>
                        <Statistic
                            title="Jackpot"
                            suffix="ETH"
                            value={Number(parsedEvent.jackpot).toFixed(2)}
                            valueStyle={{color: "#ff4d4f", fontWeight: 600, fontSize: "24px"}}
                        />
                    </Col>
                    {(parsedEvent.winner)
                        ? <Col>
                            <Statistic
                                title="Winner"
                                value={`🪦 ${bidToString(dateToBid(parsedEvent.winner))}`}
                                valueStyle={{color: "green", fontWeight: 600, fontSize: "24px"}}
                                className="blink-me"
                            />
                        </Col>
                        : ""}
                </Row>
                <br/>
                <Descriptions size="small" column={1}>
                    {(parsedEvent.minBid > 0 && showLimits)
                        ? <Descriptions.Item
                            label="Min bet"
                            labelStyle={{backgroundColor: "#ffe58f"}}
                            contentStyle={{backgroundColor: "#ffe58f", maxWidth: "100px"}}
                        >
                            {Number(parsedEvent.minBid).toFixed(2)} ETH
                        </Descriptions.Item>
                        : ""}
                    {(parsedEvent.maxBid > 0 && showLimits)
                        ? <Descriptions.Item
                            label="Max bet"
                            labelStyle={{backgroundColor: "#ffe58f"}}
                            contentStyle={{backgroundColor: "#ffe58f", maxWidth: "100px"}}
                        >
                            {Number(parsedEvent.maxBid).toFixed(2)} ETH
                        </Descriptions.Item>
                        : ""}
                    {(parsedEvent.fee > 0 && showFee)
                        ? <Descriptions.Item
                            label="Fee"
                            labelStyle={{backgroundColor: "#ffe58f"}}
                            contentStyle={{backgroundColor: "#ffe58f", maxWidth: "100px"}}
                        >
                            {Number(parsedEvent.fee * 100).toFixed(0)}%
                        </Descriptions.Item>
                        : ""}
                </Descriptions>
                <div style={styles.select}>
                    <div style={styles.textWrapper}>
                        <Text strong>Date:</Text>
                    </div>
                    <DatePicker
                        size="large"
                        style={{borderColor: "black"}}
                        allowClear={false}
                        value={selectedDates[0]}
                        disabledDate={disabledDate}
                        onChange={(date, dateString) => {
                            setSelectedDates([date]);
                            setBidName(date);
                            if (!amount) {
                                setAmount(parsedEvent.minBid);
                            }
                        }}
                    />
                </div>
                <div style={styles.select}>
                    <div style={styles.textWrapper}>
                        <Text strong>Amount:</Text>
                    </div>
                    <InputNumber
                        size="large"
                        decimalSeparator="."
                        style={{borderColor: "black", maxWidth: "160px", width: "100%"}}
                        prefix="ETH"
                        placeholder={`${parsedEvent.minBid} min`}
                        controls={false}
                        min={Number(parsedEvent.minBid)}
                        value={amount}
                        onChange={(value) => {
                            setAmount(value);
                        }}
                    />
                </div>
                {tx
                    ? <div>
                        <Alert
                            message={<span>I'd win <b>{simWinnings(amount, propsTx.amountReceived).toFixed(4)}{ETH}</b></span>}
                            type="warning"
                            showIcon
                            banner
                            style={{maxWidth: "240px"}}
                        />
                    </div>
                    : ""}
                <Button
                    type="primary"
                    size="large"
                    loading={isPending}
                    disabled={!tx || !parsedEvent.isOpen}
                    style={{width: "100%", marginTop: "25px", borderColor: "black", maxWidth: "300px"}}
                    onClick={PlaceBet}
                >
                    {parsedEvent.isOpen
                        ? "Bet 💰"
                        : "🚫 Event is closed - no more bets"}
                </Button>
                <br/><br/><br/>
                <div style={{overflowX: "auto"}}>
                    <Calendar
                        dateCellRender={dateCellRender}
                        disabledDate={disabledDate}
                        onSelect={(date) => {
                            setSelectedDates([date]);
                            setBidName(date);
                            if (!amount) {
                                setAmount(parsedEvent.minBid);
                            }
                        }}
                    />
                </div>
                <br/><br/><br/>
                <NativeTransactions bets={bets.filter(bet => bet.person === cAccounts[0])}/>
            </Spin>;
        </div>
    </div>);
}


export default DeadPool;
