import React from "react";
import {Col, Container, Row} from "react-bootstrap";

import "../../App.css";

import CreateEvent from "./CreateEvent.jsx";
import EventList from "./EventList.jsx";


function Owner() {

    return (<div className="App">
        <Container>
            <Row>
                <Col>
                    <CreateEvent/>
                </Col>
                <Col>
                    <EventList/>
                </Col>
            </Row>
        </Container>
    </div>);
}

export default Owner;
