import Web3 from "web3";

import BettingContract from "../contracts/DeadPool.json";

async function AskForPermission() {
    await window.ethereum.enable();
}

let getWeb3 = new Promise(function (resolve, reject) {
    // Wait for loading completion before loading web3, to be sure it's
    // already injected
    window.addEventListener("load", function () {
        var results;
        var web3 = window.web3;
        if (window.ethereum) {
            web3 = new Web3(window.ethereum);
            try {
                // Request account access if needed
                AskForPermission();
                // Account now exposed
                results = {
                    web3: web3,
                };
                console.log("Injected web3 detected.");
                resolve(results);
            } catch (error) {
                // User denied account access...
                window.alert("Access Denied!");
            }
        } else if (typeof web3 !== "undefined") {
            // Checking if Web3 has been injected by the browser MetaMask
            // Use MetaMask's provider.
            web3 = new Web3(web3.currentProvider);
            results = {
                web3: web3,
            };
            console.log("Injected web3 detected.");
            resolve(results);
        } else {
            // If no web3 is detected, then the local web3 provider is loaded.
            var provider = new Web3.providers.HttpProvider("http://127.0.0.1:9545");
            web3 = new Web3(provider);
            results = {
                web3: web3,
            };
            console.log("No web3 instance injected, using Local web3.");
            resolve(results);
        }
    });
});


const contract = require("@truffle/contract");
const Betting = contract(BettingContract);

let bettingInstance = getWeb3.then(results => {
    return results.web3.eth.getAccounts().then(_accounts => {
        Betting.setProvider(results.web3.currentProvider);
        return Betting.deployed().then(_instance => {
            return ({
                instance: _instance, accounts: _accounts,
            });
        });
    });
});

export default getWeb3;
export {bettingInstance};
