import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, Input, Select, Space} from "antd";
import "antd/dist/antd.css";

import {bidToDate, dateToBid, stringToBid, toHex, toString, toWei} from "../Utilities.jsx";
import {bettingInstance} from "../../utils/getWeb3.js";

const {Option} = Select;

const nextDays = 30;


function CreateEvent() {

    const [_eventName, setEventName] = useState("");
    const [betEventId, setBetEventId] = useState("");
    const [bidName, setBidName] = useState("");
    const [betEvents, setBetEvents] = useState([]);

    const defaultValue = (valueInput, defaultValue = null) => {
        const value = valueInput.value;
        defaultValue = defaultValue != null
            ? defaultValue
            : value;
        if (value == null || value === "") {
            return defaultValue;
        } else {
            return value;
        }
    };

    const inputList = [["arbitratorInput"], "eventNameInput", "feeInput", "minAmountInput", "maxAmountInput"];

    const getBetEvents = () => {
        return bettingInstance.then(({instance, accounts}) => {
            return instance.getBetEvents(accounts[0], {from: accounts[0], value: 0});
        }).then(results => setBetEvents(results));
    };

    const getInputElements = () => {
        return inputList.map(inputId => document.getElementById(inputId));
    };

    const cleanInputElements = () => {
        getInputElements().map(element => element.value = "");
    };

    const CreateEvent = () => {
        return bettingInstance.then(({instance, accounts}) => {
            const arbitratorInput = document.getElementById("arbitratorInput");
            const eventNameInput = document.getElementById("eventNameInput");
            const feeInput = document.getElementById("feeInput");
            const minAmountInput = document.getElementById("minAmountInput");
            const maxAmountInput = document.getElementById("maxAmountInput");
            const arbitrator = defaultValue(arbitratorInput.value, accounts[0]);
            const eventName = toHex(eventNameInput.value);
            const fee = Number(defaultValue(feeInput, 1));
            const minAmount = toWei(defaultValue(minAmountInput, 0).toString());
            const maxAmount = toWei(defaultValue(maxAmountInput, 0).toString());
            console.log("event name", eventName);
            cleanInputElements();
            return instance.createEvent(arbitrator, eventName, fee, minAmount, maxAmount, {
                from: accounts[0], value: 0,
            });
        }).then(results => results);
    };

    const AddBid = () => {
        return bettingInstance.then(({instance, accounts}) => {
            return instance.addBid(accounts[0], betEventId, bidName, {from: accounts[0], value: 0}).then(results => {
                console.log("new bid", results);
            });
        }).then(results => results);
    };

    const AddBids = () => {
        const start = moment.utc();
        const end = moment.utc().add(nextDays, "days");
        return bettingInstance.then(({instance, accounts}) => {
            return instance.getBids(accounts[0], betEventId, {from: accounts[0], value: 0}).then((bids) => {
                const newBids = [];
                var current = start;
                while (current <= end) {
                    const _current = current;
                    if (bids.filter(bid => Number(bid.name) === dateToBid(_current)).length === 0) {
                        newBids.push(dateToBid(current));
                    }
                    current = current.add(1, "days");
                }
                console.log("new bids", newBids);
                if (newBids.length > 0) {
                    return instance.addBids(accounts[0], betEventId, newBids, {from: accounts[0], value: 0});
                } else {
                    console.log("no bid to add");
                }
            }).then(results => results);
        }).then(results => results);
    };

    const CloseBid = () => {
        return bettingInstance.then(({instance, accounts}) => {
            return instance.closeBid(accounts[0], betEventId, bidName, {from: accounts[0], value: 0});
        }).then(results => results);
    };

    const CloseBids = () => {
        const maxDate = moment.utc();
        return bettingInstance.then(({instance, accounts}) => {
            return instance.getBids(accounts[0], betEventId, {from: accounts[0], value: 0}).then((bids) => {
                const closingBids = bids
                    .filter(bid => bidToDate(bid.name) < maxDate && bid.status === "0")
                    .map(bid => Number(bid.name));
                if (closingBids.length > 0) {
                    return instance.closeBids(accounts[0], betEventId, closingBids, {from: accounts[0], value: 0});
                } else {
                    console.log("no bid to close");
                }
            }).then(results => results);
        }).then(results => results);
    };

    const DetermineWinner = () => {
        return bettingInstance.then(({instance, accounts}) => {
            return instance.determineWinner(accounts[0], betEventId, bidName, {from: accounts[0], value: 0});
        }).then(results => results);
    };

    const FinishEvent = () => {
        return bettingInstance.then(({instance, accounts}) => {
            return instance.finishEvent(accounts[0], betEventId, {from: accounts[0], value: 0});
        }).then(results => results);
    };

    useEffect(() => {
        getBetEvents();
    });

    return (<div>
        <h3>Create New Event</h3>
        <Space direction="vertical">
            <Input
                id="arbitratorInput"
                addonBefore="Arbitrator"
                placeholder="Default to current account"
                allowClear
            />
            <Input
                id="eventNameInput"
                addonBefore="Event name"
                allowClear
                onChange={(e) => setEventName(e.target.value)}
            />
            <Input
                id="feeInput"
                addonBefore="Fee"
                suffix="%"
                placeholder="Defaults to 1%"
                allowClear
            />
            <Input
                id="minAmountInput"
                addonBefore="Min Amount"
                suffix="ETH"
                placeholder="Defaults to 0"
                allowClear
            />
            <Input
                id="maxAmountInput"
                addonBefore="Max Amount"
                suffix="ETH"
                placeholder="Defaults to +inf"
                allowClear
            />
            <Button
                type="primary"
                disabled={!_eventName}
                onClick={CreateEvent}
            >
                Create Event
            </Button>
        </Space>
        <hr/>
        <h3>Manage Event</h3>
        <Space direction="vertical">
            <Select
                id="eventIdInput"
                placeholder="Choose Event"
                allowClear
                onChange={(value, option) => setBetEventId(value)}
            >
                {betEvents.map(betEvent => <Option key={betEvent.id} value={betEvent.id}>
                    {toString(betEvent.name)}
                </Option>)}
            </Select>
            <Input
                id="bidNameInput"
                addonBefore="Bid name"
                placeholder="YYYY-MM-DD"
                allowClear
                onChange={(e) => setBidName(stringToBid(e.target.value))}
            />
            <Space direction="horizontal">
                <Button
                    disabled={!betEventId || !bidName}
                    onClick={AddBid}
                >
                    Add Bid
                </Button>
                <Button
                    disabled={!betEventId || !bidName}
                    onClick={CloseBid}
                >
                    Close Bid
                </Button>
                <Button
                    disabled={!betEventId || !bidName}
                    danger
                    onClick={DetermineWinner}
                >
                    Set Winner
                </Button>
            </Space>
            <br/>
            <Space direction="horizontal">
                <Button
                    type="primary"
                    disabled={!betEventId}
                    onClick={AddBids}
                >
                    Add Bids next {nextDays} days
                </Button>
                <Button
                    type="primary"
                    disabled={!betEventId}
                    onClick={CloseBids}
                >
                    Close Expired Bids
                </Button>
            </Space>
            <br/>
            <Button
                type="primary"
                disabled={!betEventId}
                danger
                block
                onClick={FinishEvent}
            >
                Finish Event
            </Button>
        </Space>
    </div>);


}


export default CreateEvent;
