import React, {useEffect, useState} from "react";
import {useMoralis} from "react-moralis";

import getWeb3 from "../utils/getWeb3.js";
import {fromWei} from "./Utilities.jsx";

function NativeBalance() {
    const [balance, setBalance] = useState();
    const {account, isAuthenticated} = useMoralis();

    useEffect(() => {
        getWeb3.then(results => {
            results.web3.eth.getAccounts().then(accounts => {
                results.web3.eth.getBalance(accounts[0]).then(_balance => setBalance(fromWei(_balance)));
            });
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getWeb3.then(results => {
                results.web3.eth.getAccounts().then(accounts => {
                    results.web3.eth.getBalance(accounts[0]).then(_balance => setBalance(fromWei(_balance)));
                });
            });
        }, 5000);
        return () => clearInterval(interval);
    });

    if (!account || !isAuthenticated) {
        return null;
    }

    return (<div style={{textAlign: "center", whiteSpace: "nowrap"}}>
        {balance
            ? Number(balance).toFixed(4)
            : "-"} ETH
    </div>);
}

export default NativeBalance;
