import moment from "moment";
import Web3 from "web3";
import getWeb3 from "../utils/getWeb3.js";


const wei = Web3.utils.toWei("1", "ether");

const bidMask = "YYYY-MM-DD";

function dateToBid(date) {
    // return moment(date).format(bidMask);
    return date.utc().startOf("day").unix();
}

function bidToDate(bid) {
    // return moment(bid, bidMask).toDate();
    return moment.unix(bid);
}

function bidToString(bid) {
    return moment(bidToDate(bid)).utc().format(bidMask);
}

function stringToBid(string) {
    if (moment(string, bidMask, true).isValid()) {
        return moment.utc(string, bidMask).unix();
    }
}

function fromWei(number) {
    // return Web3.utils.fromWei(Web3.utils.toBN(number), 'ether')
    return String(number / wei);
}

function toWei(number) {
    // return Web3.utils.toWei(Web3.utils.toBN(number), 'ether')
    return String(number * wei);
}

function toString(hex) {
    return Web3.utils.hexToString(hex);
}

function toHex(str, padRight = 0) {
    var hex = Web3.utils.stringToHex(str);
    if (padRight > 0) {
        hex = Web3.utils.padRight(hex, padRight);
    }
    return hex;
}

function parseBetEvent(e) {
    return {
        name: toString(e.name),
        creator: e.creator,
        arbitrator: e.arbitrator,
        status: e.status,
        winner: e.winner,
        arbitratorFee: Number(parseFloat(e.arbitratorFee)).toFixed(0) + "%",
        minBid: fromWei(e.minBid) + " ETH",
        maxBid: e.maxBid > 0
            ? fromWei(e.maxBid) + " ETH"
            : "+inf",
    };
}

function toBetEvent(e) {
    return e;
}

function _getWeb3() {
    const web3 = getWeb3.then(results => results.web3);
    return web3;
}


export {
    toWei,
    fromWei,
    toString,
    toHex,
    toBetEvent,
    parseBetEvent,
    _getWeb3,
    dateToBid,
    bidToDate,
    bidToString,
    stringToBid,
};
