import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router";
import {useMoralis} from "react-moralis";
import {Button, Layout, Menu} from "antd";
import "antd/dist/antd.css";

import {fromWei} from "./Utilities.jsx";
import {bettingInstance} from "../utils/getWeb3.js";

import Account from "./Account/Account";
import NativeBalance from "./NativeBalance";
import {poolList} from "./PoolList";

const {Header} = Layout;


const styles = {
    content: {
        display: "flex",
        justifyContent: "center",
        fontFamily: "Roboto, sans-serif",
        color: "#041836",
        marginTop: "130px",
        padding: "10px",
    }, header: {
        position: "fixed",
        zIndex: 1000,
        width: "100%",
        background: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "Roboto, sans-serif",
        borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
        padding: "0 10px",
        boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
    }, headerRight: {
        display: "flex", gap: "20px", alignItems: "center", fontSize: "15px", fontWeight: "600",
    },
};


function MenuItems({isOwner}) {

    const {pathname} = useLocation();

    return (<Menu
        theme="light"
        mode="horizontal"
        style={{
            display: "flex", fontSize: "17px", fontWeight: "500", width: "100%", justifyContent: "center",
        }}
        defaultSelectedKeys={[pathname]}
        selectedKeys={[pathname]}
    >
        <Menu.Item key="/">
            <NavLink to="/">🏦 Home</NavLink>
        </Menu.Item>
        {poolList.map((pool) => {
            return <Menu.Item key={pool.path}>
                <NavLink to={pool.path}>{pool.title}</NavLink>
            </Menu.Item>;
        })}
        {isOwner
            ? (<Menu.Item key="/deadtest">
                <NavLink to="/deadtest">DeadTest</NavLink>
            </Menu.Item>)
            : ""}
        {isOwner
            ? (<Menu.Item key="/owner">
                <NavLink to="/owner">📄 Owner</NavLink>
            </Menu.Item>)
            : ""}
    </Menu>);
}


function Navigation() {

    const [owner, setOwner] = useState("");
    const [winnings, setWinnings] = useState(0);
    const [isOwner, setIsOwner] = useState(false);
    const [isPending, setIsPending] = useState(false);

    const {isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading} = useMoralis();

    const RequestWinnings = () => {
        setIsPending(true);
        return bettingInstance.then(({instance, accounts}) => {
            return instance.requestWithdraw({from: accounts[0], value: 0});
        }).then(results => {
            setIsPending(false);
            return results;
        }).catch(() => setIsPending(false));
    };

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on("chainChanged", () => {
                window.location.reload();
            });
            window.ethereum.on("accountsChanged", () => {
                window.location.reload();
            });
        }
    });

    useEffect(() => {
        const connectorId = window.localStorage.getItem("connectorId");
        if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
            enableWeb3({provider: connectorId});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isWeb3Enabled]);

    useEffect(() => {
        const getOwner = () => {
            console.log("getting owner");
            return bettingInstance.then(({instance, accounts}) => {
                return instance.owner();
            }).then(results => {
                console.log("owner", results);
                setOwner(results);
            });
        };
        const getWinnings = () => {
            console.log("getting winnings");
            return bettingInstance.then(({instance, accounts}) => {
                return instance.pendingWithdrawals(accounts[0]);
            }).then(results => setWinnings(results)).catch(error => setWinnings(0));
        };
        getOwner();
        getWinnings();
    }, []);

    useEffect(() => {
        bettingInstance.then(({instance, accounts}) => setIsOwner(accounts[0] === owner));
    }, [owner]);

    return (<Header style={styles.header}>
        <MenuItems isOwner={isOwner}/>
        <div style={styles.headerRight}>
            {(winnings > 0)
                ? (<Button
                    type="primary"
                    size="large"
                    loading={isPending}
                    style={{width: "100%", textAlign: "center"}}
                    onClick={RequestWinnings}
                >
                    <div className="shift-container">
                        {`You won ${fromWei(winnings)} ETH 💰 \u279C`}
                    </div>
                </Button>)
                : (<div></div>)}
            <NativeBalance/>
            <Account/>
        </div>
    </Header>);
}


export default Navigation;
