import DeadPool from "./DeadPool";
import deadpootinImg from "../img/deadpootin.png";
import deadpoohpingImg from "../img/deadpoohping.png";


const CREATOR = "0xb34ccb2F8ce6704a15A05D0BB19A28987Fd249A0";

const n = 100;


function DeadTest() {

    const _props = {
        name: `deadtest${n}`, creator: CREATOR, idBetEvent: n, backgroundImage: deadpoohpingImg,
    };

    return (<DeadPool  {..._props} />);

}


function DeadPooTIN() {

    const _props = {
        name: "deadpootin", creator: CREATOR, idBetEvent: 0, backgroundImage: deadpootinImg,
    };

    return (<DeadPool  {..._props} />);

}


function DeadPoohPING() {

    const _props = {
        name: "deadpoohping", creator: CREATOR, idBetEvent: 1, backgroundImage: deadpoohpingImg,
    };

    return (<DeadPool  {..._props} />);

}


const poolListRaw = [
    {
        key: "deadpootin", path: "/deadpootin", element: <DeadPooTIN/>, title: "💀💩🥫 DeadPooTIN", active: true,
    }, {
        key: "deadpoohping", path: "/deadpoohping", element: <DeadPoohPING/>, title: "💀🐻🏓 DeadPoohPING", active: true,
    },
];

const poolList = poolListRaw.filter((pool) => pool.active);


export {DeadTest, poolList};
