import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Layout} from "antd";
import {useMoralis} from "react-moralis";

import {bettingInstance} from "./utils/getWeb3.js";
import "./App.css";
import "./index.css";

import {Home, Navigation, Owner} from "./components";
import {DeadTest, poolList} from "./components/PoolList";


const styles = {
    content: {
        display: "flex",
        justifyContent: "center",
        fontFamily: "Roboto, sans-serif",
        color: "#041836",
        marginTop: "130px",
        padding: "10px",
    }, header: {
        position: "fixed",
        zIndex: 1,
        width: "100%",
        background: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontFamily: "Roboto, sans-serif",
        borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
        padding: "0 10px",
        boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
    }, headerRight: {
        display: "flex", gap: "20px", alignItems: "center", fontSize: "15px", fontWeight: "600",
    },
};


function App({isServerInfo}) {

    const [owner, setOwner] = useState("");
    const [winnings, setWinnings] = useState(0);

    const {isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading} = useMoralis();

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on("chainChanged", () => {
                window.location.reload();
            });
            window.ethereum.on("accountsChanged", () => {
                window.location.reload();
            });
        }
    });

    useEffect(() => {
        const connectorId = window.localStorage.getItem("connectorId");
        if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
            enableWeb3({provider: connectorId});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, isWeb3Enabled]);

    useEffect(() => {
        const getOwner = () => {
            console.log("getting owner");
            return bettingInstance.then(({instance, accounts}) => {
                return instance.owner();
            }).then(results => {
                console.log("owner", results);
                setOwner(results);
            });
        };
        const getWinnings = () => {
            console.log("getting winnings");
            return bettingInstance.then(({instance, accounts}) => {
                return instance.pendingWithdrawals(accounts[0]);
            }).then(results => setWinnings(results)).catch(error => setWinnings(0));
        };
        getOwner();
        getWinnings();
    }, []);

    return (<Layout style={{height: "100vh", overflow: "auto"}}>
        <Router forceRefresh={true}>
            <Navigation
                owner={owner}
                winnings={winnings}
            />
            <div style={styles.content}>
                <Routes>
                    <Route
                        exact path="/"
                        element={<Home/>}
                    />
                    <Route
                        exact path="/owner"
                        element={<Owner/>}
                    />
                    {poolList.map((pool) => {
                        return <Route
                            key={pool.key}
                            path={pool.path}
                            element={pool.element}
                        />;
                    })}
                    <Route
                        key="deadtest"
                        exact path="/deadtest"
                        element={<DeadTest/>}
                    />
                </Routes>
            </div>
        </Router>
    </Layout>);

}


export default App;
