import React from "react";
import moment from "moment";
import {Skeleton, Table} from "antd";
import "antd/dist/antd.css";

import {bidToString, fromWei} from "./Utilities.jsx";


const styles = {
    title: {
        fontSize: "30px", fontWeight: "700",
    }, card: {
        padding: "20px",
        display: "flex",
        background: "#FFFFFF",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
        border: "2px solid #e7eaf3",
        borderRadius: "15px",
        marginBottom: "20px",
    }, table: {
        tableLayout: "fixed", width: "100%", textAlign: "left", borderCollapse: "collapse", maxWidth: "100%",
    },
};


function NativeTransactions({bets}) {

    const columns = [
        {
            title: "Timestamp",
            dataIndex: "timestamp",
            key: "timestamp",
            sorter: (a, b) => a.timestamp - b.timestamp,
            sortOrder: "descend",
            render: (timestamp, record, index) => <>{moment.unix(timestamp).format("D MMM YYYY, HH:mm:ss")}</>,
        }, {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            sorter: (a, b) => a.amount - b.amount,
            render: (amount, record, index) => <>{Number(fromWei(amount))}</>,
        }, {
            title: "Bid Date",
            dataIndex: "bidName",
            key: "bidName",
            sorter: (a, b) => a.bidName - b.bidName,
            render: (bidName, record, index) => <b>{bidToString(bidName)}</b>,
        },
    ];

    let key = 0;

    return (<div>
        <h1 style={styles.title}>💸My Bets</h1>
        <Skeleton
            loading={!bets}
        >
            <Table
                dataSource={bets}
                columns={columns}
                rowKey={(record) => {
                    key++;
                    return `${record.timestamp}-${key}`;
                }}
            />
        </Skeleton>
    </div>);
}

export default NativeTransactions;
